import React from "react"
import { Link } from "gatsby"
import Layout from "../templates/pageLayout"
import * as legalStyles from "./legalStyles.module.css"

export default function TermsConditions() {
  return (
    <Layout title="Terms & Conditions">
      <div className="container">
        <div>
          <h1>TERMS & CONDITIONS</h1>
          <div className={legalStyles.mainContainer}>
            <div>
              <h4>1. ACCEPTANCE AND USE OF TINTEX TERMS AND CONDITIONS</h4>
              <p>
                Your access to and use of Tintex is subject exclusively to these
                Terms and Conditions. You will not use the Website for any
                purpose that is unlawful or prohibited by these Terms and
                Conditions. By using the Website you are fully accepting the
                terms, conditions and disclaimers contained in this notice. If
                you do not accept these Terms and Conditions you must
                immediately stop using the Website.
              </p>
            </div>
            <div>
              <h4>2. CREDIT CARD DETAILS</h4>
              <p>
                Tintex will never ask for your credit card details via the
                Website and we advise our customers to not enter their credit
                cards details on Tintex website or by submitting such details in
                any other form.
              </p>
            </div>
            <div>
              <h4>3. LEGAL ADVICE</h4>
              <p>
                The contents of Tintex website do not constitute advice and
                should not be relied upon in making or refraining from making,
                any decision. All material contained on Tintex is provided
                without any or warranty of any kind. You use the material on
                Tintex at your own discretion
              </p>
            </div>
            <div>
              <h4>4. CHANGE OF USE</h4>
              <p>
                Tintex reserves the right to: <br />
                <br />
                4.1 Change or remove (temporarily or permanently) the Website or
                any part of it without notice and you confirm that Tintex shall
                not be liable to you for any such change or removal.
                <br /> <br />
                4.2 Change these Terms and Conditions at any time, and your
                continued use of the Website following any changes shall be
                deemed to be your acceptance of such change. <br />
                <br />
                4.3 Change the details of the services that are offered, and
                their value, at any time.
              </p>
            </div>
            <div>
              <h4>5. LINKS TO THIRD PARTY WEBSITES</h4>
              <p>
                Tintex Website may include links to third party websites that
                are controlled and maintained by others. Any link to other
                websites is not an endorsement of such websites and you
                acknowledge and agree that we are not responsible for the
                content or availability of any such sites.
              </p>
            </div>
            <div>
              <h4>6. COPYRIGHT</h4>
              <p>
                6.1 All copyright, trade marks and all other intellectual
                property rights in the Website and its content (including
                without limitation the Website design, text, graphics and all
                software and source codes connected with the Website) are owned
                by or licensed to Tintex or otherwise used by Tintex as
                permitted by law.
                <br /> <br /> 6.2 In accessing the Website you agree that you
                will access the content solely for your personal, non-commercial
                use. None of the content may be downloaded, copied, reproduced,
                transmitted, stored, sold or distributed without the prior
                written consent of the copyright holder. This excludes the
                downloading, copying and/or printing of pages of the Website for
                personal, non-commercial home use only.
              </p>
            </div>
            <div>
              <h4>7. LINKS TO AND FROM OTHER WEBSITES</h4>
              <p>
                7.1 Throughout this Website you may find links to third party
                websites. The provision of a link to such a website does not
                mean that we endorse that website. If you visit any website via
                a link on this Website you do so at your own risk.
                <br /> <br />
                7.2 Any party wishing to link to this website is entitled to do
                so provided that the conditions below are observed: <br /> (a)
                You do not seek to imply that we are endorsing the services or
                products of another party unless this has been agreed with us in
                writing;
                <br />
                (b) You do not misrepresent your relationship with this website;
                and <br />
                (c) The website from which you link to this Website does not
                contain offensive or otherwise controversial content or, content
                that infringes any intellectual property rights or other rights
                of a third party.
                <br /> <br />
                7.3 By linking to this Website in breach of our terms, you shall
                indemnify us for any loss or damage suffered to this Website as
                a result of such linking.
              </p>
            </div>
            <div>
              <h4>8. DISCLAIMERS AND LIMITATION OF LIABILITY</h4>
              <p>
                8.1 The Website is provided on an AS IS and AS AVAILABLE basis
                without any representation or endorsement made and without
                warranty of any kind whether express or implied, including but
                not limited to the implied warranties of satisfactory quality,
                fitness for a particular purpose, non-infringement,
                compatibility, security and accuracy. <br /> <br />
                8.2 To the extent permitted by law, Tintex will not be liable
                for any indirect or consequential loss or damage whatever
                (including without limitation loss of business, opportunity,
                data, profits) arising out of or in connection with the use of
                the Website.
                <br /> <br /> 8.3 Tintex makes no warranty that the
                functionality of the Website will be uninterrupted or error
                free, that defects will be corrected or that the Website or the
                server that makes it available are free of viruses or anything
                else which may be harmful or destructive.
                <br /> <br />
                8.4 Nothing in these Terms and Conditions shall be construed so
                as to exclude or limit the liability of Tintex for death or
                personal injury as a result of the negligence of Tintex or that
                of its employees or agents.
              </p>
            </div>
            <div>
              <h4>9. INDEMNITY</h4>
              <p>
                You agree to indemnify and hold Tintex and its employees and
                agents harmless from and against all liabilities, legal fees,
                damages, losses, costs and other expenses in relation to any
                claims or actions brought against Tintex arising out of any
                breach by you of these Terms and Conditions or other liabilities
                arising out of your use of this Website.
              </p>
            </div>
            <div>
              <h4>10. SEVERANCE</h4>
              <p>
                If any of these Terms and Conditions should be determined to be
                invalid, illegal or unenforceable for any reason by any court of
                competent jurisdiction then such Term or Condition shall be
                severed and the remaining Terms and Conditions shall survive and
                remain in full force and effect and continue to be binding and
                enforceable.
              </p>
            </div>
            <div>
              <h4>11. WAIVER</h4>
              <p>
                If you breach these Conditions of Use and we take no action, we
                will still be entitled to use our rights and remedies in any
                other situation where you breach these Conditions of Use.
              </p>
            </div>
            <div>
              <h4>12. WAIVER</h4>
              <p>
                These Terms and Conditions shall be governed by and construed in
                accordance with the law of UK and you hereby submit to the
                exclusive jurisdiction of the UK courts.
              </p>
            </div>
            <div>
              <h4>13. OUR CONTACT DETAILS</h4>
              <p>
                12 Fairways Business Centre,
                <br />
                Airport Service Road,
                <br />
                Portsmouth, Hampshire,
                <br />
                PO3 5NU
                <br />
                UK
                <br />
                <br />
                07479 555 325
                <br />
                info@tintex.co.uk
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
